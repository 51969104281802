import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import SectionHeader from '../../components/SectionHeader'
import SectionTextTwoColumns from '../../components/SectionTextTwoColumns'
import Layout from '../../layout/Layout'

export default function Home() {
  const { headerBg } = useStaticQuery(
    graphql`
      query {
        headerBg: file(
          relativePath: { eq: "nasze-uslugi/transport-miedzynarodowy.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  return (
    <Layout
      metaTitle="Transport międzynarodowy"
      metaDescription="Jeśli poszukujesz pewnego i sprawdzonego partnera w logistyce międzynarodowej, który gwarantuje elastyczne i indywidualne podejście do każdego zlecenia, znajdujesz się we właściwym miejscu."
      slug="/uslugi/transport-miedzynarodowy"
    >
      <SectionHeader
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            międzynarodowy
          </>
        }
        titleBorder="white"
        lead="Jeśli poszukujesz pewnego i sprawdzonego partnera w logistyce międzynarodowej, który gwarantuje elastyczne i indywidualne podejście do każdego zlecenia, znajdujesz się we właściwym miejscu."
        content="Z nami Twoje ładunki dotrą do celu niezależnie od tego, gdzie on się znajduje. Rozbudowana sieć partnerów w całej Europie sprawia, że wysyłane przez nasz zespół – potocznie mówiąc – tiry z taką samą efektywnością dostarczają Twoje towary z Polski do Niemiec, na Maltę, do Wielkiej Brytanii, Rosji, czy Szwecji, bądź w odwrotnym kierunku."
        buttonType="bordered"
        scroll="#czytaj-wiecej"
        halfBg
        bgImage={getImage(headerBg)}
      />
      <SectionTextTwoColumns
        id="czytaj-wiecej"
        smallTitle="Transport międzynarodowy"
        title={
          <>
            Organizacja <br />
            transportu <br />
            od A do Z
          </>
        }
        lead={
          <>
            Naszym zadaniem jest kompleksowa obsługa i&nbsp;kontrola procesów
            transportowych w&nbsp;zakresie zarówno eksportu, jak i&nbsp;importu.
            Od lat współpracujemy z&nbsp;polskimi przedsiębiorcami, którym
            pomagamy w&nbsp;zdobywaniu zagranicznych rynków na wschodzie,
            zachodzie, północy i&nbsp;południu Europy.
          </>
        }
      >
        <p>
          Zajmujemy się też obsługą wymiany gospodarczej obejmującej działalność
          przedsiębiorstw importowych, które korzystają z&nbsp;naszych usług
          w&nbsp;celu zlecenia przewozu towarów z&nbsp;rynków zagranicznych.
          Realizujemy również transporty kabotażowe.
        </p>
        <p>
          Dostarczamy do Polski i&nbsp;wysyłamy znad Wisły wszelkiego rodzaju
          ładunki – obsługujemy dowolne ilości przedmiotów, paliw, czy obiektów.
          Logistyka międzynarodowa to nasza specjalność – wiemy, jak ją
          zorganizować w&nbsp;sposób efektywny i&nbsp;optymalny pod względem
          kosztów. Doświadczenie pozwala nam na dobór najlepszych tras, które
          umożliwiają bezpieczny i&nbsp;zarazem szybki transport do każdego
          celu. Czy to będą Niemcy, Malta, Francja, Norwegia, czy Ukraina –
          docieramy wszędzie tam, gdzie możliwy jest przewóz towarów pojazdami
          „TIR”.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport międzynarodowy"
        title={
          <>
            Twój transport <br />
            pod kontrolą
          </>
        }
        lead={
          <>
            Doskonale zdajemy sobie sprawę ze stopnia skomplikowania logistyki
            międzynarodowej, dlatego gwarantujemy dobór sprawdzonych oraz
            rzetelnych Podwykonawców. Tworzymy doświadczony zespół spedytorów,
            którzy dysponują niezbędnymi kwalifikacjami i&nbsp;certyfikatami.
          </>
        }
      >
        <p>
          Z powodzeniem obsługujemy zarówno wielkie firmy produkcyjne, jak
          i&nbsp;mniejsze przedsiębiorstwa handlowe, niezależnie od branży.
          Zapraszamy do współpracy bez względu na to, czy ma być stała, czy
          jednorazowa. Dysponujemy odpowiednimi zasobami, które pozwalają na
          sprawną realizację nawet najtrudniejszych i&nbsp;najbardziej
          skomplikowanych zleceń.
        </p>
        <p>
          Zdajemy sobie sprawę, że chcesz mieć pełną wiedzę na temat swoich
          ładunków, dlatego gwarancja szybkiego kontaktu z&nbsp;dedykowanym
          opiekunem jest zawsze stałym elementem naszej oferty. Każdy –
          potocznie zwany – TIR wiozący Twoje towary znajduje się pod kontrolą
          i&nbsp;w&nbsp;dowolnym momencie możesz otrzymać informacje na temat
          jego lokalizacji czy przewidywanego czasu dotarcia do celu.
          Terminowość i&nbsp;bezpieczeństwo dostaw to dwa najważniejsze aspekty,
          którym podporządkowujemy nasze usługi. Zlecając nam transport, masz
          pewność, że Twoje ładunki znajdą się u&nbsp;celu tak szybko, jak to
          tylko możliwe.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport międzynarodowy"
        title={
          <>
            Logistyka międzynarodowa <br />
            na najwyższym <br />
            poziomie
          </>
        }
        lead={
          <>
            Ponieważ współpracujemy wyłącznie z&nbsp;wysoko wykwalifikowanymi
            i&nbsp;doświadczonymi Kierowcami, wiemy, że wykonają zlecenie
            profesjonalnie i&nbsp;z&nbsp;dbałością o&nbsp;każdy szczegół.
          </>
        }
      >
        <p>
          Troszczymy się o&nbsp;najwyższą jakość świadczonych usług,
          a&nbsp;jednocześnie proponujemy konkurencyjne warunki finansowe
          współpracy. Dobór rozwiązań ograniczających koszty do minimum pozwala
          nam na zapewnienie korzystnej dla Klienta relacji ceny do jakości, tak
          aby przy relatywnie niewielkich nakładach miał pewność rzetelnie,
          szybko i&nbsp;bezpiecznie wykonanego transportu z&nbsp;i&nbsp;do
          Polski.
        </p>
        <p>
          Stawiamy na uczciwość współpracy, dlatego proponujemy jasne warunki,
          minimum formalności i&nbsp;kompleksową realizację zlecenia.
          Organizujemy transport międzynarodowy między innymi do Niemiec,
          Francji, UK, Hiszpanii, Rosji, na Ukrainę, a&nbsp;nawet na Maltę
          w&nbsp;sposób kompleksowy – zapewniamy pełną logistykę Twoich towarów,
          tak aby jak najsprawniej znalazły się we wskazanym miejscu.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport międzynarodowy"
        title={
          <>
            Sprawdzony <br />
            partner w&nbsp;transporcie
          </>
        }
        lead={
          <>
            Dążymy do tego, żeby w&nbsp;jak największym stopniu odciążyć
            Klientów od wszelkich czynności związanych z&nbsp;logistyką
            międzynarodową z&nbsp;i&nbsp;do Polski – nie tylko organizujemy sam
            transport, ale też pomagamy w&nbsp;formalnościach i&nbsp;sprawach
            administracyjnych.
          </>
        }
      >
        <p>
          Gwarantujemy wsparcie w&nbsp;czynnościach celnych w&nbsp;przypadku
          towarów, które tego wymagają. O&nbsp;ile w&nbsp;krajach Unii
          Europejskiej zazwyczaj nie jest to wymagane, o&nbsp;tyle przy imporcie
          i&nbsp;eksporcie poza obszar UE staje się to konieczne. Proponujemy
          też wsparcie przy zakupie ubezpieczeń CARGO, dzięki czemu oszczędzasz
          czas na formalnościach związanych z wyborem i&nbsp;nabyciem polisy.
        </p>
        <p>
          Każdy transport z&nbsp;i&nbsp;do Polski poprzedzamy dokładną analizą
          Twoich potrzeb i&nbsp;wymagań, a&nbsp;następnie pod ich kątem układamy
          strategię realizacji. Dobieramy metody i&nbsp;techniki spedycji ściśle
          pod kątem specyfiki oraz ilości Twoich ładunków, wymaganego czasu
          dostawy lub odbioru, a&nbsp;także wielkości budżetu przeznaczonego na
          transport międzynarodowy TIR. Optymalizujemy procesy spedycyjne
          z&nbsp;myślą o&nbsp;wykonaniu wydajnego, szybkiego i&nbsp;bezpiecznego
          przewozu towarów. Jeżeli poszukujesz kompleksowego
          i&nbsp;profesjonalnego wsparcia w&nbsp;logistyce, możesz na nas
          liczyć. Skontaktuj się i&nbsp;przekaż nam, jak możemy Ci pomóc!
        </p>
      </SectionTextTwoColumns>
    </Layout>
  )
}
